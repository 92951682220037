import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import Patient from "./Patient";
import { deleteCookie, getCookie, setCookie } from "../lib/utils/cookies";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { getDoctorAvailability } from "../lib/apis/doctor";
import { hitRefreshToken } from "../lib/apis/user";
import {
  checkAvailability,
  getBookingListByAvailabilityId,
} from "../lib/apis/booking";
import {
  Booking,
  DocAvailability,
  Doctor,
  SessionAdjustment,
} from "../lib/utils/types";
import { useInterval } from "../lib/utils/useInterval";
import ProfilePicture from "./ProfilePicture";
import ReactDropdown, { Option } from "react-dropdown";
import useSpeakNextPatientName from "../lib/customHooks/useSpeakNextPatient";
import { getAudioLanguageFromLocalStorage } from "../lib/utils/speechUtils";

export const smoothScrollTo = (
  target: number,
  duration: number,
  callback?: () => void
): { cancel: () => void } => {
  const start = window.scrollY || window.pageYOffset;
  const distance = target - start;
  let startTime: number | null = null;
  let animationFrameId: number;

  const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const step = (currentTime: number) => {
    if (!startTime) startTime = currentTime;
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1);
    const newY = easeInOutQuad(elapsed, start, distance, duration);
    window.scrollTo(0, newY);
    if (elapsed < duration) {
      animationFrameId = requestAnimationFrame(step);
    } else {
      if (callback) callback();
    }
  };

  animationFrameId = requestAnimationFrame(step);

  return {
    cancel: () => cancelAnimationFrame(animationFrameId),
  };
};

const QueueSlide = ({ mapping_id,carouselView,isToggled }: { mapping_id: string,carouselView:boolean,isToggled:boolean }) => {
  const { hospData } = useHospDocData();
  const accessToken = getCookie("accessToken");
  const refreshToken = getCookie("refreshToken");
  const navigate = useNavigate();

  const [docDetails, setDocDetails] = useState<Doctor>();
  const [docAvail, setDocAvail] = useState<DocAvailability[]>();
  const [SelectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [index, setIndex] = useState<number | undefined>(moment().day() + 1);
  const [inClinicData, setInClinicData] = useState<Booking[]>();
  const [options, setOptions] = useState<Option[]>();
  const [session, setSession] = useState<Option>();
  const [onGoing, setOnGoing] = useState<Booking>();
  const [pageSize, setPageSize] = useState(5);

  const timeoutsRef = useRef<number[]>([]);
  const intervalsRef = useRef<number[]>([]);
  const animationsRef = useRef<ReturnType<typeof smoothScrollTo>[]>([]);

  // custom hook to speak the next patient name
    useSpeakNextPatientName(getAudioLanguageFromLocalStorage(), docDetails?.full_name, onGoing?.booking_alias || onGoing?.full_name, onGoing?.booking_id);

  useEffect(() => {
    // Scrolling speed settings
    const scrollDuration: number = 5000; // 5 seconds for scrolling
    const waitAtBottom: number = 5000; // 5 seconds at bottom
    const cycleDuration: number = scrollDuration * 2 + waitAtBottom; // Total cycle

    const scrollToBottom = () => {
      const targetPosition = document.body.scrollHeight;
      const animation = smoothScrollTo(targetPosition, scrollDuration);
      animationsRef.current.push(animation);
    };

    const scrollToTop = () => {
      const targetPosition = 0;
      const animation = smoothScrollTo(targetPosition, scrollDuration);
      animationsRef.current.push(animation);
    };

    // Initial scroll down after 2 seconds
    const initialScrollDownTimeout = window.setTimeout(scrollToBottom, 4000);
    timeoutsRef.current.push(initialScrollDownTimeout);

    // Set up the interval to repeat the scrolling cycle
    const scrollInterval = window.setInterval(() => {
      scrollToBottom();

      const scrollUpTimeout = window.setTimeout(() => {
        scrollToTop();
      }, waitAtBottom);
      timeoutsRef.current.push(scrollUpTimeout);
    }, cycleDuration);
    intervalsRef.current.push(scrollInterval);

    return () => {
      // Clear all timeouts
      timeoutsRef.current.forEach((timeout) => window.clearTimeout(timeout));
      // Clear all intervals
      intervalsRef.current.forEach((interval) =>
        window.clearInterval(interval)
      );
      // Cancel all ongoing animations
      animationsRef.current.forEach((animation) => animation.cancel());
    };
  }, []);

  const fetchQueueData = async (availability_id: string) => {
    const inclinic_data = await getBookingListByAvailabilityId(
      availability_id,
      SelectedDate
    );
    console.log("fetch");
    if (inclinic_data?.status === 200) {
      console.log("bookings", inclinic_data.data.result);
      const data: Booking[] = inclinic_data.data.result;
      setInClinicData(
        data
          .sort((a, b) => a.token_number - b.token_number)
          .filter((item) => item.status === 1 || item.status === 2)
      );
      const ongoingPatient = data.filter((item) => item.status === 2)[0];
      console.log(ongoingPatient);
      if (ongoingPatient !== undefined) {
        setOnGoing(ongoingPatient);

        // BROWSER SPEECH SYNTHESIS
        // if (onGoing?.token_number !== ongoingPatient.token_number) {
        //   console.log("Triggering Speech for:", ongoingPatient.full_name);
        //   const text = `Next scanning patient for ${docDetails?.full_name} is ${ongoingPatient.full_name}`;
        //   speakText(text)
        // }
      }
    } else if (inclinic_data?.status === 403 && accessToken && refreshToken) {
      const refresh_data = await hitRefreshToken(accessToken, refreshToken);
      if (refresh_data?.status === 200) {
        console.log("Refresh");
        setCookie("accessToken", refresh_data.data.result.access_token, 30);
        setCookie("refreshToken", refresh_data.data.result.refresh_token, 30);
        const api_data = await getBookingListByAvailabilityId(
          session?.value,
          SelectedDate
        );
        if (api_data?.status === 200) {
          const data: Booking[] = api_data.data.result;
          setInClinicData(
            data
              .sort((a, b) => a.token_number - b.token_number)
              .filter((item: Booking) => item.status === 1 || item.status === 2)
          );
        }
      } else {
        deleteCookie("accessToken");
        deleteCookie("refreshToken");
        deleteCookie("hospID");
        navigate("/");
      }
    } else setInClinicData(undefined);
  };

  useEffect(() => {
    setSelectedDate(moment().format("YYYY-MM-DD"));
    setIndex(moment().day() + 1);
  }, []);

  useEffect(() => {
    const fetchDocAvailability = async () => {
      const res = await getDoctorAvailability(mapping_id);
      if (res?.status === 200) {
        // console.log(res.data.result);
        setDocAvail(res.data.result.doctor_availability);
        setDocDetails(res.data.result.doctor_details);
      } else if (res?.status === 403 && accessToken && refreshToken) {
        const refresh_data = await hitRefreshToken(accessToken, refreshToken);
        if (refresh_data?.status === 200) {
          console.log("Refresh");
          setCookie("accessToken", refresh_data.data.result.access_token, 30);
          setCookie("refreshToken", refresh_data.data.result.refresh_token, 30);
          const res = await getDoctorAvailability(mapping_id);
          if (res?.status === 200) {
            setDocAvail(res.data.result.doctor_availability);
            setDocDetails(res.data.result.doctor_details);
          }
        }
      }
    };
    fetchDocAvailability();
  }, [mapping_id, hospData]);

  useEffect(() => {
    if (docAvail !== undefined) {
      const data: Option[] = docAvail
        .filter((i) => i.day_of_week === index)
        .map((item) => {
          const startTimeMoment = moment(item.start_time, "HH:mm:ss");
          const endTimeMoment = moment(item.end_time, "HH:mm:ss");

          return {
            value: String(item.availability_id),
            label: `${startTimeMoment.format(
              "hh:mmA"
            )} - ${endTimeMoment.format("hh:mmA")}`,
            data: {
              wait_time: item.wait_time.minutes,
              queue_type: item.queue_type,
              max_patients: item.max_patients_per_slot,
            },
            start_time: startTimeMoment, // Assigning moment object
            end_time: endTimeMoment, // Assigning moment object
          };
        })
        .sort((a, b) => {
          // Sort by ascending order of time
          return a.start_time.diff(b.start_time);
        });

      const fetchExtension = async () => {
        let newOptions = data.slice();

        for (let i = 0; i < data.length; i++) {
          try {
            const res = await checkAvailability(data[i].value, SelectedDate);
            console.log("checkAvailability", res?.data.result);

            if (res?.status === 200 && res.data.result.length > 0) {
              const api_data: SessionAdjustment[] = res.data.result;

              api_data.forEach((apiResult) => {
                if (apiResult.type === "Unavailability") {
                  newOptions = newOptions.map((session) => {
                    if (apiResult.availability_id === session.value) {
                      return {
                        ...session,
                        label: "Unavailable Session",
                        data: {
                          ...session.data,
                        },
                      };
                    }
                    return session;
                  });
                } else if (apiResult.type === "Extension") {
                  newOptions = newOptions.map((session) => {
                    if (apiResult.availability_id === session.value) {
                      return {
                        ...session,
                        label: `${moment(
                          apiResult.session_start_time,
                          "HH:mm:ss"
                        ).format("hh:mmA")} - ${moment(
                          apiResult.extended_end_time,
                          "HH:mm:ss"
                        ).format("hh:mmA")}`,
                        data: {
                          ...session.data,
                        },
                      };
                    }
                    return session;
                  });
                }
              });
            } else if (res?.status === 404) {
              console.log(
                "Session ID " + data[i].value + " not found, no changes made."
              );
            } else {
              throw new Error("Unhandled response status: " + res.status);
            }
          } catch (error) {
            console.error("Error fetching session availability: ", error);
          }
        }

        setOptions(newOptions);

        const now = moment();
        // const now = moment().set({ hour: 19, minute: 0 });
        console.log(newOptions);
        const currSession = newOptions
          .filter((item) => {
            const startTime = moment(
              String(item?.label)?.split(" - ")[0],
              "HH:mmA"
            ).subtract(30, "minutes"); // Subtract 30 minutes from start time
            const endTime = moment(
              String(item?.label)?.split(" - ")[1],
              "HH:mmA"
            ).add(30, "minutes"); // Add 30 minutes to end time

            return now.isBetween(startTime, endTime); // Check if current time is within the modified range
          })
          .map((item) => {
            return {
              value: String(item.value),
              label: `${moment(
                String(item?.label)?.split(" - ")[0],
                "HH:mm:ss"
              ).format("hh:mmA")} - ${moment(
                String(item?.label)?.split(" - ")[1],
                "HH:mm:ss"
              ).format("hh:mmA")}`,
              data: {
                wait_time: item.data?.wait_time,
                queue_type: item.data?.queue_type,
              },
            };
          });

        console.log(currSession);
        if (currSession && currSession.length > 0) {
          const updatedSession = newOptions.find(
            (opt) => opt.value === currSession[0].value
          );
          setSession(updatedSession || newOptions[0]);

          fetchQueueData(updatedSession?.value || newOptions[0].value);
        } else if (newOptions.length > 0) {
          setSession(newOptions[0]);
          fetchQueueData(newOptions[0].value);
        } else {
          console.log("No available sessions found");
          setOptions([]);

          // Optionally, handle the case when all sessions are unavailable or removed
        }
      };

      fetchExtension();
    }
  }, [, SelectedDate, docAvail]);

  useInterval(async () => {
    if (session?.value) {
      fetchQueueData(session?.value);
    }
  }, 5000);

  // console.log(inClinicData);

  const changeOption: ((arg: Option) => void) | undefined = async (
    arg: Option
  ) => {
    setInClinicData(undefined);
    setSession(arg);
    fetchQueueData(String(arg.value));
  };

  return (
    <div>
      <div className={carouselView?"flex flex-row justify-between items-start mt-12 mb-12  ":"flex flex-row justify-between items-start mt-12 mb-12 "}>
        <div className="flex flex-row ">
          {docDetails?.profile_picture.startsWith("http") ? (
            <img
              className={carouselView?'!w-[50px] !h-[50px] rounded-lg items-center':`w-[50px] h-[50px] rounded-lg items-center`}
              src={docDetails?.profile_picture}
              alt="doctor"
            ></img>
          ) : (
            <ProfilePicture username={docDetails?.full_name || ""} className={carouselView?"rounded-lg":"rounded-lg"} />
          )}

          <div className={carouselView?"ml-3 flex flex-col justify-center":"ml-3 flex flex-col justify-center"}>
            <p className="text-black text-lg ">Dr. {docDetails?.full_name} Queue</p>
            <p className="flex items-start text-xs text-[#9A9A9A]  ">
              {docDetails?.qualification}
            </p>
          </div>
        </div>
        <div className="flex flex-row space-x-4 justify-center">
          {!carouselView && inClinicData?.length ? (
            <select
              name="pageSize"
              id="pageSize"
              className="appearance-none w-[50px] h-[50px] text-lg text-center bg-[#8E8E8E33] rounded-full cursor-pointer outline-0"
              defaultValue={5}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
          ) : (
            <></>
          )}
          <ReactDropdown
            options={options ? options : []}
            onChange={changeOption}
            value={session}
            placeholder={"Select a session"}
            menuClassName="rounded-xl !mt-1"
            controlClassName="!p-4 !py-3 !rounded-[80px] !self-center !bg-[#2751D2] !text-white !cursor-pointer"
            arrowClassName="!hidden"
          />
        </div>
      </div>

      <>
        {inClinicData?.length ? (
          <>
            <div>
              {/* {inClinicData?.filter((item) => item.status === 2).length !==
              0 ? ( */}
              {inClinicData
                ?.filter((item) => item.status === 2 )
                .map((item, index) => (
                  <Patient
                    onGoing
                    key={index}
                    pos={item.token_number}
                    name={item.booking_alias || item.full_name}
                    queue_type={String(session?.data?.queue_type)}
                    type={item.booking_mode}
                    patientKey={index + 1}
                    status={item.status}
                    isToggled={isToggled}
                    hospitalID={hospData?.hospital_id}
                    slotStartTime={item?.booked_slot_start}
                    slotEndTime={item?.booked_slot_end}
                  />
                ))}
              {/* ) : (
                <Patient notStarted />
              )} */}
            </div>

            <div>
              {inClinicData?.filter((item) => item.status === 1).length !== 0 ||
              onGoing ? (
                inClinicData
                  ?.filter(
                    (item) =>
                      item.status === 1 &&
                      item.availability_id === session?.value
                  )
                  .slice(0, pageSize-1)
                  .map((item, index) => (
                    <Patient
                      key={index}
                      pos={item.token_number}
                      name={item.booking_alias || item.full_name}
                      type={item.booking_mode}
                      patientKey={index + 1}
                      isToggled={isToggled}
                      hospitalID={hospData?.hospital_id}
                      slotStartTime={item?.booked_slot_start}
                      slotEndTime={item?.booked_slot_end}
                    />
                  ))
              ) : (
                <Patient empty text={"No Patients in the clinic"} carousel={carouselView} isToggled={isToggled}/>
              )}
            </div>
          </>
        ) : (
          <Patient empty text={"No Patients in the clinic"} carousel={carouselView} isToggled={isToggled}/>
        )}
      </>
    </div>
  );
};

export default QueueSlide;
