import moment from "moment";

function SlotTimings({ start, end, isToggled,style }: { start: string|undefined; end: string | undefined, isToggled?: boolean,style?:string }) {
    return (
      <div className={`text-md mx-1 bg-[#24222026] px-4 py-4 text-black rounded-full justify-center text-center  ${style}`} >
        {moment(start, "HH:mm:ss").format("hh:mmA")}{" - "}
        {end === null
          ? moment(start, "HH:mm:ss").format("hh:mmA")
          : moment(end, "HH:mm:ss").format("hh:mmA")}
      </div>
    );
  }

  export default SlotTimings;