import React, { useEffect, useState } from "react";
import moment from "moment";
import LiveQueue from "../components/LiveQueue";
import Loader from "../components/Loader";
import { useUserData } from "../lib/contexts/UserContext";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { useInterval } from "../lib/utils/useInterval";
import { useNavigate } from "react-router-dom";
import ScrollingText from "../components/ScrollingText";

const ColumnView = () => {
  const { userData } = useUserData();
  const { hospitalID, doctors } = useHospDocData();
  const navigate = useNavigate();

  const [time, setTime] = useState(moment().format("hh:mm A"));
  // console.log("doctors", doctors);

  useInterval(async () => {
    setTime(moment().format("hh:mm A"));
  }, 60000);

  useEffect(() => {
    if (doctors === undefined) navigate("/" + hospitalID);
  }, [doctors]);

  return (
    <>
      {userData !== undefined ? (
        <div className="flex flex-col h-screen">
          <div className="flex flex-row justify-between mt-5">
            <p className="font-semibold text-2xl ml-10">{time}</p>
            <button onClick={() => navigate("/admin")} className="w-[8%] mr-10">
              <img
                src={require("../assets/images/DTlogo.png")}
                alt="Queue empty"
              />
            </button>
          </div>
          <div
            className={`grid grid-cols-1 ${
              doctors?.length === 2 ? "grid-cols-2" : "lg:grid-cols-3"
            } gap-5 pb-20`}
          >
            {doctors?.map((doc, index) => (
              <LiveQueue key={index} mapping_id={doc.mapping_id} />
            ))}
          </div>
          {hospitalID === "3635f86c-6baf-4fbc-99b1-b47d555afce0" && (
            <ScrollingText />
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ColumnView;
