import { useRef, useEffect } from 'react';
import { synthesyzeSpeech } from '../apis/speech';
import { getAudioLanguageFromLocalStorage } from '../utils/speechUtils';
import { getCookie } from '../utils/cookies';
import { useNavigate } from 'react-router-dom';
import { refreshAccessToken } from '../utils/authUtil';

const AudioManager = (() => {
  let currentAudio: HTMLAudioElement | null = null;

  const play = async (audioContent: string) => {
    if (currentAudio) {
      // If audio is already playing, stop it
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }

    currentAudio = new Audio(`data:audio/mp3;base64,${audioContent}`);

    try {
      await currentAudio.play();
      await new Promise((resolve) => currentAudio && currentAudio.addEventListener('ended', resolve, { once: true }));
      await currentAudio.play();
      await new Promise((resolve) => currentAudio && currentAudio.addEventListener('ended', resolve, { once: true }));
    } catch (error) {
      console.error('Error playing audio:', error);
    } finally {
      currentAudio = null; // Reset after playing
    }
  };

  const stop = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      currentAudio = null;
    }
  };

  return {
    play,
    stop,
  };
})();

const useSpeakNextPatientName = (
  language: string = getAudioLanguageFromLocalStorage(),
  doctorName: string | undefined,
  patientName: string | undefined,
  bookingId: string | undefined
) => {
  const lastSpokenPatientRef = useRef<string | undefined>(undefined); // Track last spoken patient name
  const audioRef = useRef<HTMLAudioElement | null>(null); // Track audio element
  const videoElementsRef = useRef<NodeListOf<HTMLVideoElement> | null>(null); // Track video elements

  const accessToken = getCookie("accessToken");
  const refreshToken = getCookie("refreshToken");

  const navigate = useNavigate();

  const patientToCall = JSON.stringify({ name: patientName?.toLowerCase(), bookingId, lang: language });

  // Function to mute all video elements
  const muteAllVideos = () => {
    videoElementsRef.current = document.querySelectorAll("video");
    videoElementsRef.current.forEach((video) => (video.muted = true));
  };

  // Function to unmute all video elements
  const unmuteAllVideos = () => {
    if (videoElementsRef.current) {
      videoElementsRef.current.forEach((video) => (video.muted = false));
    }
  };

  async function playAudio(audioContent: string) {
    // Mute videos before playing
    muteAllVideos();

    try {
      await AudioManager.play(audioContent);
      // Reset the state after audio has played
      lastSpokenPatientRef.current = patientName;
    } catch (error) {
      console.error('Error playing audio:', error);
    } finally {
      unmuteAllVideos(); // Unmute videos after speaking
    }
  }

  const speakPatientName = async () => {
    // Prevent speaking the same patient again if already speaking
    if (lastSpokenPatientRef.current === patientName) {
      return;
    }

    // Stop current audio if another patient is called or language is changed
    AudioManager.stop();

    // Generate audio for patient name
    const res = await synthesyzeSpeech(language, doctorName?.toLowerCase(), patientName?.toLowerCase(), bookingId);
    if (!res) return;

    if (res.status === 200) {
      const audioContent = res.data.result;
      await playAudio(audioContent);
    } else if (res.status === 403 && accessToken && refreshToken) {
      const isTokenRefreshed = await refreshAccessToken(accessToken, refreshToken);

      if (isTokenRefreshed) {
        // Retry API call after refreshing token
        const retryRes = await synthesyzeSpeech(language, doctorName?.toLowerCase(), patientName?.toLowerCase(), bookingId);
        if (!retryRes) return;
        if (retryRes.status === 200) {
          const audioContent = retryRes.data.result;
          await playAudio(audioContent);
        }
      } else {
        navigate('/');
      }
    }
  };

  const initiateSpeak = async () => {
    if (!patientName || !doctorName) return;
    const previousCalledPatient = JSON.parse(localStorage.getItem("previousCalledPatient") || "{}");

    if (previousCalledPatient === patientToCall) {
      return;
    }

    await speakPatientName();
    localStorage.setItem("previousCalledPatient", patientToCall);
  };

  useEffect(() => {
    if (!patientName || !doctorName) return;
    initiateSpeak();
  }, [doctorName, patientName, bookingId, language]);

  // Cleanup function to stop any ongoing audio and unmute videos when component unmounts or dependencies change
  useEffect(() => {
    return () => {
      AudioManager.stop(); // Stop audio when the component unmounts or dependencies change
      unmuteAllVideos(); // Ensure videos are unmuted on cleanup
    };
  }, [doctorName, patientName, bookingId, language]);
};

export default useSpeakNextPatientName;
